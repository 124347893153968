export const clientNameFooter = "Karin Yoga";
export const titleMain = "";
export const subtitleMain = "";

export const aspectRatioLogoAbout = '2'; // width/height
export const aspectRatioLogoMain = '2'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "Yoga com Karin";
export const nomeFornecedor = "Karin";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://instagram.com/yogacomkarin?igshid=MzRlODBiNWFlZA==",
    profile: "@yogacomkarin"
  },
  {
    // type: "material-community",
    // name: "youtube",
    // link: "https://www.youtube.com",
  },

  {
    type: "material-community",
    name: "whatsapp",
    link: "https://wa.me/351912513420",
    number: "+351 912 513 420"
  },
];

export const drawerClosedLinks = [
  // {
  //   title: "Fale Comigo",
  //   link: "https://instagram.com",
  // },
];

export const linkAppleStore = "https://apps.apple.com/us/app/karin-yoga/id6463042729"
export const linkPlayStore = "https://play.google.com/store/apps/details?id=app.web.mobile_karin_yoga.twa"

export const hasOutsideAboutPage = false;
export const drawerOpenedLinks = [];